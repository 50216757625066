import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { subscribeWithSelector } from "zustand/middleware";
import {
  ProductUploadMapping,
  ProductUploadRuleItem,
  XmlFeed,
  XmlFeedFormat,
  XmlFeedTemplateType,
} from "feed-common";
import { ulid } from "ulid";
import {
  makeMapping,
  makeRuleItem,
  ProfileHasChanges,
} from "../utils/xml-feed.utils";

type Props = {
  originalProfile: XmlFeed | null;
  profile: XmlFeed | null;
  changed: boolean;
  error: string;
  nameError: string;
  formatError: string;
  profileIsSaving: boolean;
  profileSaveStatus: boolean | null;
  profileIsDeleting: boolean;
  profileDeleteStatus: boolean | null;
  profileSource: XmlFeedTemplateType | null;
  isProfileNew: boolean;
  profileIsSet: boolean;
  itemErrors: Record<string, string>;
};

type Actions = {
  deleteRuleItem: (id: string) => void;
  setProfile: (data: XmlFeed, isNew: boolean) => void;
  resetProfile: () => void;
  hasChanges: () => boolean;
  setProfileIsSaving: (status: boolean) => void;
  setProfileSaveStatus: (status: boolean | null) => void;
  setProfileIsDeleting: (status: boolean) => void;
  setProfileDeleteStatus: (status: boolean | null) => void;
  addRulesSection: () => void;
  setFormat: (format: XmlFeedFormat) => void;
  setName: (name: string) => void;
  copyProfile: () => void;
  deleteRuleSection: (sectionId: string) => void;
  addRuleItem: (sectionId: string) => void;
  setError: (error: string) => void;
  setNameError: (error: string) => void;
  setFormatError: (error: string) => void;
  setItemError: (id: string, error: string) => void;
  updateRuleItem: (
    itemId: string,
    data: Omit<ProductUploadRuleItem, "id">
  ) => void;
  deleteMapping: (mappingId: string) => void;
  updateMapping: (
    mappingId: string,
    mapping: Partial<ProductUploadMapping>
  ) => void;
  addMappingRuleSection: (mappingId: string) => void;
  deleteMappingRuleSections: (mappingId: string) => void;
  addMapping: () => void;
  setOriginalProfile: (data: XmlFeed) => void;
  resetStore: () => void;
  setUpdateSchedule: (schedule: string) => void;
  setTimezone: (timezone: string) => void;
};

const initialState: Props = {
  profile: null,
  originalProfile: null,
  changed: false,
  error: "",
  nameError: "",
  formatError: "",
  profileIsSaving: false,
  profileSaveStatus: null,
  profileIsDeleting: false,
  profileDeleteStatus: null,
  profileSource: null,
  isProfileNew: true,
  profileIsSet: false,
  itemErrors: {},
};

export const useProfileStore = create<Props & Actions>()(
  subscribeWithSelector(
    immer((set, get) => ({
      ...initialState,

      setProfile: (data: XmlFeed, isNew: boolean) => {
        set(() => ({
          profile: data,
          isProfileNew: isNew,
          profileIsSet: true,
          changed: ProfileHasChanges(get().originalProfile, data),
          originalProfile: isNew ? null : data,
          error: "",
          nameError: "",
          formatError: "",
          itemErrors: {},
        }));
      },
      setOriginalProfile: (data: XmlFeed) => {
        set(() => ({
          originalProfile: data,
          changed: ProfileHasChanges(data, get().profile),
          isProfileNew: false,
        }));
      },
      resetProfile: () => set(() => initialState),
      hasChanges: () => get().changed,
      setProfileIsSaving: (status: boolean) =>
        set(() => ({ profileIsSaving: status })),
      setProfileSaveStatus: (status: boolean | null) =>
        set(() => ({ profileSaveStatus: status })),
      setProfileIsDeleting: (status: boolean) =>
        set(() => ({ profileIsDeleting: status })),
      setProfileDeleteStatus: (status: boolean | null) =>
        set(() => ({ profileDeleteStatus: status })),
      addRulesSection: () =>
        set((state) => {
          state.profile?.rules.sections.push({
            id: ulid(),
            ruleItems: [makeRuleItem()],
          });
          state.changed = ProfileHasChanges(
            state.originalProfile,
            state.profile
          );
        }),
      setProfileSource: (data: XmlFeedTemplateType) =>
        set(() => ({ profileSource: data })),
      setFormat: (format: XmlFeedFormat) =>
        set((state) => {
          state.profile!.format = format;
          state.changed = ProfileHasChanges(
            state.originalProfile,
            state.profile
          );
        }),
      setName: (name: string) =>
        set((state) => {
          state.profile!.name = name;
          state.changed = ProfileHasChanges(
            state.originalProfile,
            state.profile
          );
        }),
      copyProfile: () =>
        set((state) => {
          state.profile = {
            ...state.profile!,
            id: ulid(),
            name: state.profile!.name + " (copy)",
            active: false,
            total_uploads_number: 0,
          };
          state.changed = true;
        }),
      deleteRuleSection: (sectionId: string) => {
        if (get().profile?.rules.sections.find((s) => s.id === sectionId)) {
          set((state) => {
            state.profile!.rules.sections =
              state.profile?.rules.sections.filter((s) => s.id !== sectionId) ??
              [];
          });
        } else {
          get().profile!.mappings.forEach((m, mappingIndex) => {
            if (
              m.rules.sections.find((s: { id: string }) => s.id === sectionId)
            ) {
              set((state) => {
                // @ts-ignore
                state.profile!.mappings[mappingIndex]!.rules!.sections =
                  state.profile?.mappings[mappingIndex].rules.sections.filter(
                    (s: { id: string }) => s.id !== sectionId
                  );
              });
            }
          });
        }
        set((state) => ({
          changed: ProfileHasChanges(state.originalProfile, state.profile),
        }));
      },
      addRuleItem: (sectionId: string) => {
        const ruleSectionIndex = get().profile!.rules.sections.findIndex(
          (s) => s.id === sectionId
        );

        if (ruleSectionIndex !== -1) {
          set((state) => {
            state.profile!.rules.sections[ruleSectionIndex].ruleItems.push(
              makeRuleItem()
            );
            state.changed = ProfileHasChanges(
              state.originalProfile,
              state.profile
            );
          });
        } else {
          let sectionIndex = -1;
          let mappingIndex = -1;
          get().profile!.mappings.forEach((m, i) => {
            m.rules.sections.forEach((s, j) => {
              if (s.id === sectionId) {
                sectionIndex = j;
                mappingIndex = i;
              }
            });
          });

          if (mappingIndex !== -1 && sectionIndex !== -1) {
            set((state) => {
              state.profile!.mappings[mappingIndex].rules.sections[
                sectionIndex
              ].ruleItems.push(makeRuleItem());
              state.changed = ProfileHasChanges(
                state.originalProfile,
                state.profile
              );
            });
          } else {
            console.error("Section not found");
          }
        }
      },
      setError: (error: string) =>
        set((state) => {
          state.error = error;
        }),
      setNameError: (error: string) =>
        set((state) => {
          state.nameError = error;
        }),
      setFormatError: (error: string) =>
        set((state) => {
          state.formatError = error;
        }),
      setItemError: (id: string, error: string) => {
        set((state) => {
          state.itemErrors[id] = error;
        });
      },
      updateRuleItem: (
        itemId: string,
        data: Omit<ProductUploadRuleItem, "id">
      ) => {
        let sectionIndex: number | null = null;
        let ruleIndex: number | null = null;

        get().profile!.rules.sections.forEach((s, i) => {
          const index = s.ruleItems.findIndex((r) => r.id === itemId);

          if (index !== -1) {
            sectionIndex = i;
            ruleIndex = index;
          }
        });

        if (ruleIndex !== null && sectionIndex !== null) {
          set((state) => {
            state.profile!.rules.sections[sectionIndex as number].ruleItems[
              ruleIndex as number
            ].attribute = data.attribute;
            state.profile!.rules.sections[sectionIndex as number].ruleItems[
              ruleIndex as number
            ].operator = data.operator;
            state.profile!.rules.sections[sectionIndex as number].ruleItems[
              ruleIndex as number
            ].value = data.value;
            state.changed = ProfileHasChanges(
              state.originalProfile,
              state.profile
            );
          });
        } else {
          let mappingIndex: number | null = null;
          let sectionIndex: number | null = null;
          let ruleIndex: number | null = null;

          get().profile!.mappings.forEach((m, i) => {
            m.rules.sections.forEach((s, j) => {
              s.ruleItems.forEach((r, k) => {
                if (r.id === itemId) {
                  mappingIndex = i;
                  sectionIndex = j;
                  ruleIndex = k;
                }
              });
            });
          });

          if (
            ruleIndex !== null &&
            sectionIndex !== null &&
            mappingIndex !== null
          ) {
            set((state) => {
              state.profile!.mappings[mappingIndex as number].rules.sections[
                sectionIndex as number
              ].ruleItems[ruleIndex as number].attribute = data.attribute;
              state.profile!.mappings[mappingIndex as number].rules.sections[
                sectionIndex as number
              ].ruleItems[ruleIndex as number].operator = data.operator;
              state.profile!.mappings[mappingIndex as number].rules.sections[
                sectionIndex as number
              ].ruleItems[ruleIndex as number].value = data.value;
              state.changed = ProfileHasChanges(
                state.originalProfile,
                state.profile
              );
            });
          }
        }
      },
      deleteRuleItem: (id: string) => {
        let sectionIndex = -1;
        get().profile!.rules.sections.forEach((s, i) => {
          const index = s.ruleItems.findIndex((r) => r.id === id);

          if (index !== -1) {
            sectionIndex = i;
          }
        });

        if (sectionIndex !== -1) {
          set((state) => {
            state.profile!.rules.sections[sectionIndex].ruleItems =
              state.profile!.rules.sections[sectionIndex].ruleItems.filter(
                (r) => r.id !== id
              );
            state.changed = ProfileHasChanges(
              state.originalProfile,
              state.profile
            );
          });
        } else {
          let mappingIndex = -1;
          let sectionIndex = -1;
          get().profile!.mappings.forEach((m, i) => {
            m.rules.sections.forEach((s, j) => {
              const index = s.ruleItems.findIndex((r) => r.id === id);

              if (index !== -1) {
                mappingIndex = i;
                sectionIndex = j;
              }
            });
          });

          if (mappingIndex !== -1 && sectionIndex !== -1) {
            set((state) => {
              state.profile!.mappings[mappingIndex].rules.sections[
                sectionIndex
              ].ruleItems = state.profile!.mappings[
                mappingIndex
              ].rules.sections[sectionIndex].ruleItems.filter(
                (r) => r.id !== id
              );
              state.changed = ProfileHasChanges(
                state.originalProfile,
                state.profile
              );
            });
          } else {
            console.error("Section not found");
          }
        }
      },
      deleteMapping: (mappingId: string) => {
        set((state) => {
          state.profile!.mappings = state.profile!.mappings.filter(
            (m) => m.id !== mappingId
          );
          state.changed = ProfileHasChanges(
            state.originalProfile,
            state.profile
          );
        });
      },
      updateMapping: (
        mappingId: string,
        mapping: Partial<ProductUploadMapping>
      ) => {
        const index = get().profile!.mappings.findIndex(
          (m) => m.id === mappingId
        );

        if (index !== -1) {
          set((state) => {
            state.profile!.mappings[index] = {
              ...state.profile!.mappings[index],
              ...mapping,
            };
            state.changed = ProfileHasChanges(
              state.originalProfile,
              state.profile
            );
          });
        }
      },
      addMappingRuleSection: (mappingId: string) => {
        const index = get().profile!.mappings.findIndex(
          (m) => m.id === mappingId
        );

        if (index !== -1) {
          set((state) => {
            state.profile!.mappings[index].rules.sections.push({
              id: ulid(),
              ruleItems: [makeRuleItem()],
            });
            state.changed = ProfileHasChanges(
              state.originalProfile,
              state.profile
            );
          });
        } else {
          console.error("Mapping not found");
        }
      },
      deleteMappingRuleSections: (mappingId: string) => {
        const index = get().profile!.mappings.findIndex(
          (m) => m.id === mappingId
        );

        if (index !== -1) {
          set((state) => {
            state.profile!.mappings[index].rules.sections = [];
            state.changed = ProfileHasChanges(
              state.originalProfile,
              state.profile
            );
          });
        } else {
          console.error("Mapping not found");
        }
      },
      addMapping: () => {
        set((state) => {
          state.profile!.mappings.push(makeMapping());
          state.changed = ProfileHasChanges(
            state.originalProfile,
            state.profile
          );
        });
      },
      resetStore: () => set(() => initialState),
      setUpdateSchedule: (schedule: string) =>
        set((state) => {
          state.profile!.updateSchedule = schedule;
          state.changed = ProfileHasChanges(
            state.originalProfile,
            state.profile
          );
        }),
      setTimezone: (timezone: string) =>
        set((state) => {
          state.profile!.timezone = timezone;
          state.changed = ProfileHasChanges(
            state.originalProfile,
            state.profile
          );
        }),
    }))
  )
);
