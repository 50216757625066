import {
  compareMappings,
  compareRules,
  ProductUploadMapping,
  ProductUploadMapSource,
  ProductUploadRuleItem,
  XmlFeed,
  XmlFeedTemplateType,
  XMLFeedType,
} from "feed-common";
import { ulid } from "ulid";
import { generateRandomName } from "./utils";

export function covertXmlTemplateToFeed(
  template: XmlFeedTemplateType,
  type: XMLFeedType
): XmlFeed {
  return {
    id: ulid(),
    type,
    format: null,
    name: generateRandomName(),
    active: false,
    rules: { sections: [] },
    mappings: template.mapping.map((t) => ({
      id: ulid(),
      attribute: t.attribute,
      value: t.defaultValue,
      rules: {
        sections:
          t.rules?.sections?.map((s) => ({
            id: ulid(),
            ruleItems: s.ruleItems.map((r) => ({
              id: ulid(),
              attribute: r.attribute,
              operator: r.operator,
              value: r.value,
            })),
          })) ?? [],
      },
    })),
    total_uploads_number: 0,
  };
}

export function ProfileHasChanges(
  old?: XmlFeed | null,
  current?: XmlFeed | null
): boolean {
  if (!old) {
    return true;
  }

  if (!current) {
    return false;
  }

  const nameChanged = current.name !== old.name;
  const rulesChanged = !compareRules(old.rules, current.rules);
  const mappingsChanged = !compareMappings(old.mappings, current.mappings);
  const updateScheduleChanged = !compareUpdateSchedule(
    old.updateSchedule,
    current.updateSchedule
  );
  const formatChanged = current.format !== old.format;

  if (nameChanged) {
    console.debug(`Name changed: ${old.name} -> ${current.name}`);
  } else if (rulesChanged) {
    console.debug("Rules changed");
  } else if (mappingsChanged) {
    console.debug("Mappings changed");
  } else if (updateScheduleChanged) {
    console.debug(
      `Update schedule changed: ${old.updateSchedule} -> ${current.updateSchedule}`
    );
  } else if (formatChanged) {
    console.debug(`Format changed: ${old.format} -> ${current.format}`);
  }

  return (
    nameChanged ||
    rulesChanged ||
    mappingsChanged ||
    updateScheduleChanged ||
    formatChanged
  );
}

export function isRulesDifferent(old: XmlFeed, current: XmlFeed): boolean {
  return !compareRules(old.rules, current.rules);
}

export function isMappingsDifferent(old: XmlFeed, current: XmlFeed): boolean {
  return !compareMappings(old.mappings, current.mappings);
}

export function makeRuleItem(): ProductUploadRuleItem {
  return {
    attribute: "",
    operator: "",
    value: "",
    id: ulid(),
  };
}

export function makeMapping(attribute?: ProductUploadMapSource["attribute"]) {
  return {
    attribute: attribute ?? "",
    value: "",
    rules: { sections: [] },
    id: ulid(),
  };
}

export function compareMappingAttributes(
  m1: ProductUploadMapping[],
  m2: ProductUploadMapping[]
): boolean {
  return (
    m1.length === m2.length &&
    m1.every((m) => m2.find((m2) => m2.attribute === m.attribute))
  );
}

export function compareUpdateSchedule(
  cron1?: string | null,
  cron2?: string | null
): boolean {
  if (!cron1 && !cron2) {
    return true;
  }

  return cron1 === cron2;
}
