import { Banner } from "@shopify/polaris";
import { Queries } from "../query/query-client";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { sseSubscribe, sseUnsubscribe } from "../hooks/see-events";

export function TopMessages() {
  const [messages, setMessages] = useState<string[]>([]);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const { data } = useQuery<
    unknown,
    unknown,
    {
      plan: {
        code: string;
        level: string;
        products: number;
        profiles: number;
        price: number;
        isTrailing?: boolean;
        trialDaysLeft?: number;
      };
      activeProfilesCount: number;
      variantsCount: number;
      productsCount: number;
      trialProductsLimit: number;
      isFreemium: boolean;
      freemiumTrialDays: number;
    }
  >([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);

  const noPlansMessage =
    "You currently don't have any active subscription plans. In order to prevent feeds from being automatically deleted, please subscribe.";
  const toManySkuMessage =
    "Your store contains more SKUs than the free trial permits. Please choose a subscription plan to continue using the application.";
  const toManyProfilesMessage =
    "You have exceeded the number of profiles allowed by your subscription plan. Please subscribe to a bigger plan or delete some active profiles to fit within your current plan limits.";
  const toManySkusForPlanMessage =
    "You have exceeded the number of SKUs allowed by your subscription plan. Please subscribe to a bigger plan which covers all your SKUs.";

  useEffect(() => {
    if (isOnboarding) {
      setMessages((current) =>
        current.filter(
          (m) =>
            m !== noPlansMessage &&
            m !== toManySkuMessage &&
            !m.startsWith(
              "There are more SKUs in your store than the freemium plan would allow"
            ) &&
            m !== toManyProfilesMessage &&
            m !== toManySkusForPlanMessage
        )
      );
      return;
    }
    if (data) {
      if (!data.plan) {
        if (
          (data.activeProfilesCount > 0 || data.variantsCount > 0) &&
          !messages.includes(noPlansMessage)
        ) {
          setMessages((current) => current.concat(noPlansMessage));
        }

        if (
          data.trialProductsLimit <
          Math.max(data.variantsCount, data.productsCount)
        ) {
          let message = toManySkuMessage;

          if (data.isFreemium) {
            message = `There are more SKUs in your store than the freemium plan would allow. Kindly select a subscription and get ${data.freemiumTrialDays} days of free trial to keep using the application.`;
          }
          if (!messages.includes(message)) {
            setMessages((current) => current.concat(message));
          }
        }
      } else {
        if (
          data.activeProfilesCount > data.plan.profiles &&
          !messages.includes(toManyProfilesMessage)
        ) {
          setMessages((current) => current.concat(toManyProfilesMessage));
        }

        if (
          data.variantsCount > data.plan.products &&
          !messages.includes(toManySkusForPlanMessage)
        ) {
          setMessages((current) => current.concat(toManySkusForPlanMessage));
        }
      }
    }
  }, [data, isOnboarding, messages]);

  const eventHandler = useCallback(
    (event: { subType: string; status: boolean }) => {
      const onboardingMessage =
        "The app is being configured. It could take a while. Please wait...";

      if (event.subType === "onboard-end") {
        setIsOnboarding(event.status);

        if (event.status) {
          if (!messages.includes(onboardingMessage)) {
            setMessages((current) => current.concat(onboardingMessage));
          }
        } else {
          setMessages((current) =>
            current.filter((m) => m !== onboardingMessage)
          );
        }
      }
    },
    [messages]
  );

  // Onboarding message
  useEffect(() => {
    sseSubscribe(eventHandler);

    return () => {
      sseUnsubscribe(eventHandler);
    };
  }, [eventHandler]);

  return (
    <div>
      {messages.map((m, i) => (
        <Banner key={i} tone="critical">
          {m}
        </Banner>
      ))}
    </div>
  );
}
