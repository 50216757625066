import { LegacyStack, Tag, Autocomplete } from "@shopify/polaris";
import { useState, useCallback, useMemo, useEffect } from "react";
import { getObjectHash } from "../utils/utils";

type Props = {
  values: { value: string; label: string }[];
  selected?: string[];
  placeholder?: string;
  title?: string;
  autocomplete?: "on" | "off";
  listTitle?: string;
  loading?: boolean;
  onSelect?: (selected: string[]) => void;
  error?: string;
  setError: (error: string) => void;
};

export const DELETED_ITEM_WARNING =
  "One of the selected items do not exist anymore. Remove it from selection, otherwise it may affect functionality";

export function AutocompleteComponent({
  values,
  selected,
  title,
  placeholder,
  autocomplete = "on",
  listTitle = "Start typing to search",
  loading = false,
  error,
  setError,
  onSelect,
}: Readonly<Props>) {
  const deselectedOptions = useMemo(() => values, [values]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    selected ?? []
  );
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(deselectedOptions);

  useEffect(() => {
    if (
      selected?.length &&
      selected.some((s) => !values.map((v) => v.value).includes(s))
    ) {
      setError(DELETED_ITEM_WARNING);
    } else if (error === DELETED_ITEM_WARNING) {
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObjectHash(selected), getObjectHash(values), error]);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );

      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const removeTag = useCallback(
    (tag: string) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options);

      if (onSelect) {
        onSelect(options);
      }
    },
    [onSelect, selectedOptions]
  );

  const onSelectedChange = (selected: string[]) => {
    setSelectedOptions(selected);

    if (onSelect) {
      onSelect(selected);
    }
  };

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions.map((option) => {
          const value = deselectedOptions.find((o) => o.value === option);

          return (
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              <span style={{ color: value ? "inherit" : "red" }}>
                {value?.label ?? "MISSING"}
              </span>
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={title}
      value={inputValue}
      placeholder={placeholder}
      verticalContent={verticalContentMarkup}
      autoComplete={autocomplete}
      error={error}
    />
  );

  return (
    <Autocomplete
      allowMultiple
      options={options}
      selected={selectedOptions}
      textField={textField}
      onSelect={onSelectedChange}
      listTitle={deselectedOptions.length > 0 ? listTitle : "List is empty"}
      loading={loading}
    />
  );
}
