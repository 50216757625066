import { QueryClient } from "@tanstack/react-query";
import { addGoogleMutations } from "./mutations/google.mutations";
import { addProductMutations } from "./mutations/product.mutations";
import { processResponse } from "../utils/query";
import { addGoogleQueries } from "./queries/google.queries";
import { addProductQueries } from "./queries/products.queries";
import { addCompanyQueries } from "./queries/company.queries";
import { addOauthQueries } from "./queries/oath.queries";
import { addMessageQueries } from "./queries/message.queries";
import { addMessagesMutions } from "./mutations/message.mutations";
import { addCompanyMutations } from "./mutations/company.mutations";
import { sseSubscribe } from "../hooks/see-events";

export enum Queries {
  PRODUCT_GET_GMC = "getProductsGmc",
  PRODUCT_GMC_COUNT_BY_STATUS = "getProductsCountByStatus",
  PRODUCT_GET_COLLECTIONS = "getCollections",
  PRODUCT_GET_TYPES = "getProductTypes",
  PRODUCT_GET_VENDORS = "getProductVendors",
  PRODUCT_GET_TAGS = "getProductTags",
  PRODUCT_GET_OPTIONS = "getProductOptions",
  PRODUCT_GET_UPLOAD_RULES = "getProductUploadRules",
  GOOGLE_GET_CATEGORIES = "getGoogleCategories",
  GOOGLE_GET_MERCHANT_ACCOUNTS = "getGoogleMerchantAccounts",
  GOOGLE_GET_TOKEN_INFO = "getGoogleTokenInfo",
  GOOGLE_GET_OAUTH_PAGE = "getGoogleOauthPage",
  GOOGLE_GET_ADS_ACCOUNTS = "getGoogleAdsAccounts",
  COMPANY_GET_INFO = "getCompanyInfo",
  COMPANY_GET_SHOP_INFO = "getShopInfo",
  COMPANY_GET_SUBSCRIPTIONS = "getSubscriptions",
  COMPANY_GET_PLANS_LIST = "getPlansList",
  COMPANY_GET_STATS = "getStats",
  COMPANY_GET_LIMITS = "getLimits",
  COMPANY_SUBSCRIPTION_STATISTICS = "getSubscriptionStatistics",
  COMPANY_PROFILES_TOTAL_LIST = "getProfilesTotalList",
  COMPANY_PROFILES_ACTIVE_STATS = "getProfilesActiveStats",
  OAUTH_GET_MISSING_PERMISSIONS = "getMissingPermissions",
  MESSAGES_GET_ALL = "getMessages",
  PRODUCT_GET_XML_FEEDS = "getXmlFeeds",
  PRODUCT_GET_XML_FEED_ERRORS = "getXmlFeedErrors",
  PRODUCT_META_DEFINITIONS = "getMetaDefinitions",
}

export enum Mutations {
  PRODUCT_SYNC_ALL = "syncProducts",
  PRODUCT_SET_GOOGLE_CATEGORY = "setGoogleCategory",
  PRODUCT_SET_UPLOAD_PROFILE = "setUploadProfile",
  PRODUCT_DELETE_UPLOAD_PROFILE = "deleteUploadProfile",
  GOOGLE_PRODUCT_DELETE = "GOOGLEdeleteProducts",
  GOOGLE_UPLOAD_PRODUCTS_BULK = "googleUploadProductsBulk",
  GOOGLE_REVOKE_ACCESS = "revokeGoogleAccess",
  GOOGLE_PRODUCT_ADD = "googleAppProduct",
  GOOGLE_SET_MERCHANT_ACCOUNT = "setGoogleMerchantAccount",
  GOOGLE_ADS_SET_ACCOUNT_STATUS = "setGoogleAdsAccountStatus",
  MESSAGE_DELETE = "deleteMessage",
  COMPANY_CANCEL_SUBSCRIPTION = "cancelSubscription",
  COMPANY_CREATE_SUBSCRIPTION = "createSubscription",
  PRODUCT_SET_XML_FEED = "setXmlFeed",
  PRODUCT_DELETE_XML_FEED = "deleteXmlFeed",
  PRODUCT_UPLOAD_XML_FEED = "uploadXmlFeed",
  PRODUCT_DEACTIVATE_XML_FEED = "deactivateXmlFeed",
}

const defaultQueryFn = async ({ queryKey }: { queryKey: string[] }) => {
  return fetch(queryKey[0]).then(processResponse);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn as any,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
    mutations: {
      retry: false,
    },
  },
});

addProductMutations(queryClient);
addGoogleMutations(queryClient);
addMessagesMutions(queryClient);
addCompanyMutations(queryClient);

addProductQueries(queryClient);
addGoogleQueries(queryClient);
addCompanyQueries(queryClient);
addOauthQueries(queryClient);
addMessageQueries(queryClient);

sseSubscribe((event) => {
  if (event.subType === "feed-upload" && event.meta?.finished) {
    queryClient.invalidateQueries([Queries.PRODUCT_GET_XML_FEEDS]);
    queryClient.invalidateQueries([Queries.PRODUCT_GET_XML_FEED_ERRORS]);
    queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
    queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
    queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
  }

  if (event.subType === "shopify-products-sync" && event.meta?.finished) {
    queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
  }

  if (event.subType === "gmc-upload" && event.meta?.finished) {
    queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
    queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
    queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
  }

  if (event.subType === "gmc-issues" && event.meta?.finished) {
    queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
    queryClient.invalidateQueries([Queries.COMPANY_PROFILES_TOTAL_LIST]);
    queryClient.invalidateQueries([Queries.COMPANY_PROFILES_ACTIVE_STATS]);
  }

  if (event.subType === "onboard-end" && !event.status) {
    queryClient.invalidateQueries([Queries.COMPANY_SUBSCRIPTION_STATISTICS]);
    queryClient.invalidateQueries([Queries.COMPANY_GET_SUBSCRIPTIONS]);
    queryClient.invalidateQueries([Queries.COMPANY_GET_PLANS_LIST]);
    queryClient.invalidateQueries([Queries.COMPANY_GET_STATS]);
  }
});
