import { Grid, Select, TextField } from "@shopify/polaris";
import {
  ProductUploadRuleFilterType,
  ProductUploadRuleOperatorType,
  ValueType,
  ProductUploadRuleItem,
  ProductUploadRuleFilters,
  RULES_ATTRIBUTES,
} from "feed-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CollectionsList } from "./data_sources/CollectionsListComponent";
import { ProductTypesList } from "./data_sources/ProductTypesListComponent";
import { TagsListComponent } from "./data_sources/TagsListComponent";
import { ProductVendorsList } from "./data_sources/VendorsListComponent";
import { addSelectDefaultEmptyValue, isSet } from "../../../utils/utils";
import { ButtonRemoveCircle } from "../../buttons/Buttons";
import { useProfileStore } from "../../../store/profile.store";
import { useShallow } from "zustand/react/shallow";

type Props = {
  item: ProductUploadRuleItem;
  filters: ProductUploadRuleFilterType[];
};

export function ProductUploadRuleRow({ item, filters }: Readonly<Props>) {
  const { error, setItemError, updateRuleItem, deleteRuleItem } =
    useProfileStore(
      useShallow((state) => ({
        setItemError: state.setItemError,
        updateRuleItem: state.updateRuleItem,
        deleteRuleItem: state.deleteRuleItem,
        error: state.itemErrors[item.id],
      }))
    );

  const [attribute, setAttribute] = useState<string>(item.attribute);
  const [operator, setOperator] = useState<string>(item.operator);
  const [value, setValue] = useState<ValueType>(item.value);
  const [operators, setOperators] = useState<ProductUploadRuleOperatorType[]>(
    filters.find((r) => r.value === item.attribute)?.operators ?? []
  );

  const attributes = useMemo(() => {
    return [
      { label: "Select Attribute", value: "", disabled: true },
      ...filters,
    ];
  }, [filters]);

  useEffect(() => {
    updateRuleItem(item.id, {
      attribute,
      operator,
      value,
    });
  }, [attribute, item.id, operator, updateRuleItem, value]);

  const changeAttributeHandler = useCallback(
    (attr: string) => {
      const filterItem = filters.find((r) => r.value === attr);
      setAttribute(attr);
      setOperator(filterItem?.operators[0]?.value ?? "");
      setValue("");
    },
    [filters]
  );

  const changeOperatorHandler = useCallback((op: string) => {
    setOperator(op);
  }, []);

  const changeValueHandler = useCallback((val: ValueType) => {
    setValue(val);
  }, []);

  const setErrorHandler = useCallback(
    (error: string) => {
      setItemError(item.id, error);
    },
    [item.id, setItemError]
  );

  const deleteRuleItemHandler = useCallback(() => {
    deleteRuleItem(item.id);
  }, [deleteRuleItem, item.id]);

  useEffect(() => {
    const filterItem = filters.find((r) => r.value === attribute);

    if (filterItem) {
      setOperators(filterItem?.operators ?? []);
    }
  }, [attribute, filters]);

  useEffect(() => {
    if (
      attribute &&
      operator &&
      !isSet(value) &&
      (operators.find((op) => op.value === operator) as any)?.unary !== true
    ) {
      setItemError(item.id, "Value is required");
    } else {
      setItemError(item.id, "");
    }
  }, [attribute, item.id, operator, operators, setItemError, value]);

  const isValueFieldDisabled = useMemo(() => {
    return (
      (operators.find((op) => op.value === operator) as any)?.unary === true ||
      false
    );
  }, [operator, operators]);

  useEffect(() => {
    const subscribe = useProfileStore.subscribe(
      (state) => state.itemErrors,
      (errors) => {
        if (errors[item.id]) {
          console.debug("Error in rule item", errors[item.id]);
        }
      }
    );
    return () => subscribe();
  }, [item.id]);

  return (
    <>
      <Grid.Cell columnSpan={{ xs: 6 }}>
        <Select
          label="attribute"
          options={attributes}
          value={attribute}
          onChange={changeAttributeHandler}
        />
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 4 }}>
        <Select
          label="condition"
          options={operators.map((op) => ({
            value: op.value,
            label: op.label,
          }))}
          value={operator}
          onChange={changeOperatorHandler}
          disabled={operators.length === 0}
        />
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 6 }}>
        {attribute === "title" && (
          <TextField
            label="Title"
            autoComplete="on"
            value={value as string}
            onChange={changeValueHandler}
            error={error}
          />
        )}
        {attribute === "collection" && (
          <CollectionsList
            onSelect={changeValueHandler}
            selected={value as string[]}
            error={error}
            setError={setErrorHandler}
          />
        )}
        {attribute === "vendor" && (
          <ProductVendorsList
            onSelect={changeValueHandler}
            selected={value as string[]}
            error={error}
            setError={setErrorHandler}
          />
        )}
        {attribute === "product_type" && (
          <ProductTypesList
            onSelect={changeValueHandler}
            selected={value as string[]}
            error={error}
            setError={setErrorHandler}
          />
        )}
        {attribute === "tag" && (
          <TagsListComponent
            onSelect={changeValueHandler}
            selected={value as string[]}
            error={error}
            setError={setErrorHandler}
          />
        )}
        {attribute === "inventory_policy" && (
          <Select
            label="value"
            onChange={changeValueHandler}
            value={value as string}
            error={error}
            options={addSelectDefaultEmptyValue(
              ProductUploadRuleFilters.find(
                (r) => r.value === "inventory_policy"
              )?.values ?? []
            )}
          ></Select>
        )}
        {attribute === "inventory_quantity" && (
          <TextField
            label="value"
            onChange={changeValueHandler}
            value={value as string}
            error={error}
            type="number"
            autoComplete="off"
          ></TextField>
        )}
        {attribute === "price" && (
          <TextField
            label="value"
            onChange={changeValueHandler}
            value={value as string}
            error={error}
            type="number"
            autoComplete="off"
          ></TextField>
        )}
        {[RULES_ATTRIBUTES.DESCRIPTION, RULES_ATTRIBUTES.IMAGE].includes(
          attribute as RULES_ATTRIBUTES
        ) && (
          <TextField
            label="value"
            onChange={changeValueHandler}
            value={value as string}
            error={error}
            autoComplete="off"
            disabled={isValueFieldDisabled}
          ></TextField>
        )}
        {(attribute.startsWith("metafield.") ||
          attribute.startsWith("source.")) && (
          <TextField
            label="value"
            onChange={changeValueHandler}
            value={value as string}
            error={error}
            autoComplete="off"
            disabled={isValueFieldDisabled}
          ></TextField>
        )}
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 1 }}>
        <div style={{ display: "flex", height: 80, alignItems: "center" }}>
          <ButtonRemoveCircle onClick={deleteRuleItemHandler} />
        </div>
      </Grid.Cell>
    </>
  );
}
